@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* @font-face {
  font-family: "Montserrat";
  src: url("./utils/Fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
} */

* {
  font-family: "Montserrat",sans-serif !important;
  /* -webkit-tap-highlight-color: transparent; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -webkit-transform: translateZ(0);
  transform: translateZ(0); */
}


::-webkit-scrollbar {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

@keyframes fadeInOut {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.BlueIcon path {
  fill: #2275a1;
}

.BlackIcon path {
  fill: #000000;
}

.WhiteIcon:hover path {
  fill: #ffffff;
}

.marquee {
  align-items: baseline !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}

.swiper-pagination {
  justify-content: center;
  display: flex;
  align-items: center;
}

.swiper-pagination span {
  background: linear-gradient(233.71deg, #4FBD96 19.21%, #2275A1 88.98%);
  opacity: 0.1;

}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

.swiper-pagination-web .swiper-pagination span {
  width: 15px;
  height: 15px;
}

.swiper-pagination-web .swiper-pagination-bullet-active {
  width: 25px !important;
  height: 25px !important;
}

.swiper-pagination-tab .swiper-pagination span {
  width: 12px;
  height: 12px;
}

.swiper-pagination-tab .swiper-pagination-bullet-active {
  width: 18px !important;
  height: 18px !important;
}


textarea {
  resize: none;
}

input::placeholder {
  color: #7d7d7daa;
  font-weight: 400;
}

textarea::placeholder {
  color: #7d7d7daa;
  font-weight: 400;
}

svg {
  will-change: transform;
}

.menu-enter {
  opacity: 0;
  /* transform: scale(0.9); */
}

.menu-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.menu-exit {
  opacity: 1;
}

.menu-exit-active {
  opacity: 0;
  /* transform: scale(0.9); */
  transition: opacity 300ms, transform 300ms;
}

.file-upload-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upload-box {
  width: 100%;
  padding: 40px;
  border: 1px dashed #d3d3d3;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-box:hover {
  background-color: #eef5ff;
}

.file-label {
  font-size: 16px;
  color: #003366;
}

.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-details {
  margin-top: 15px;
  background-color: #f0f8ff;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  color: #333;
}

.file-details p {
  margin: 5px 0;
  font-size: 14px;
}

.react-datepicker__input-container {
  width: 100%;
  height: 50px;
  background: #EAF4FA;
  border-radius: 8px;
}


.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  background: #EAF4FA;
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 12px;
}

.react-datepicker__input-container input:focus-visible {
  outline: none !important;
}
@media(max-width:500px) {
  .react-datepicker__input-container {
    height: 38px !important;
  }
  .upload-box{
    padding: 20px;
  }
}
@layer components {
  .F-JC-AI-CENTER {
    @apply flex items-center justify-center;
  }

  .NavText {
    @apply text-[15px] text-her-sub-heading;
  }

  .HeadingText {
    @apply text-Heading font-bold text-duskwood max_md:text-[25px];
  }

  .SubHeadingText {
    @apply text-SubHeading font-medium text-her-sub-heading max_md:text-SmallSize;
  }

  .PageSeperaterText {
    @apply text-PageSeperater font-bold uppercase tracking-widest text-duskwood max_md:text-[14px];
  }

  .PageContentText {
    @apply text-LargeSize font-normal text-her-sub-heading max_md:text-SmallSize;
  }

  .ProcessCardHeading {
    @apply text-ExLargeSize font-medium tracking-tight text-duskwood max_md:text-[20px];
  }

  .ProcessCardText {
    @apply text-SmallSize font-semibold text-stone-cold max_xl:text-SmallSize;
  }

  .TechCardHeading {
    @apply text-RegularSize font-semibold text-duskwood max_md:text-RegularSize;
  }

  .TechCardContent {
    @apply text-RegularSize font-medium text-her-sub-heading max_md:text-[14px];
  }

  .HireCardHeading {
    @apply text-LargeSize font-semibold text-bateau max_xl:text-MediumSize;
  }

  .PageTitle {
    @apply text-PageTitle font-bold text-duskwood max_md:text-LargeSize;
  }

  .PageContent {
    @apply text-LargeSize text-her-sub-heading max_md:text-SmallSize;
  }

  .FooterHeading {
    @apply text-[18px] font-semibold text-duskwood max_md:text-MediumSize;
  }

  .FooterContent {
    @apply text-RegularSize font-medium text-her-sub-heading max_md:text-SmallSize;
  }
}

.react-calendar{
  z-index: 50;
}